<template>
  <div class="home">
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Bienvenue {{ this.$data.user }}!
    </h2>
    <div class="grid gap-6 mb-8 md:grid-cols-2">
      <router-link
        to="/accounts"
        class="min-w-0 p-6 text-white bg-purple-600 rounded-lg shadow-xs"
      >
        <span class="mb-4 font-semibold">
          Utilisateurs
        </span>
      </router-link>

      <router-link
        to="/categories"
        class="min-w-0 p-6 text-white bg-purple-600 rounded-lg shadow-xs"
      >
        <span class="mb-4 font-semibold">
          Catégories
        </span>
      </router-link>

      <router-link
        to="/directory-types"
        class="min-w-0 p-6 text-white bg-purple-600 rounded-lg shadow-xs"
      >
        <span class="mb-4 font-semibold">
          Catégories d'annuaire
        </span>
      </router-link>

      <router-link
        to="/directories"
        class="min-w-0 p-6 text-white bg-purple-600 rounded-lg shadow-xs"
      >
        <span class="mb-4 font-semibold">
          Annuaire
        </span>
      </router-link>
      <router-link
        to="/ambassadors"
        class="min-w-0 p-6 text-white bg-purple-600 rounded-lg shadow-xs"
      >
        <span class="mb-4 font-semibold">
          Ambassadeurs
        </span>
      </router-link>
      <router-link
        to="/prises"
        class="min-w-0 p-6 text-white bg-purple-600 rounded-lg shadow-xs"
      >
        <span class="mb-4 font-semibold">
          Prises
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import auth from "@/utils/auth";

export default {
  data() {
    return {
      user: null
    };
  },
  async mounted() {
    auth.getUser().then(user => {
      this.$data.user = user.full_name;
    });
  }
};
</script>
